import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import GetStarted from "../components/getStarted";

const PlaybookArticle = ({ data: { mdx }, location }) => {
  const { lang } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={mdx.frontmatter.title}
        metas={[
          {
            name: "description",
            property: "og:description",
            content: mdx.frontmatter.metaDescription,
          },
          {
            name: "site_name",
            property: "og:site_name",
            content: "Addingwell",
          },
          {
            name: "url",
            property: "og:url",
            content: `${process.env.GATSBY_WEBSITE_URL}/${mdx.parent.relativeDirectory}`,
          },
          { name: "type", property: "og:type", content: "article" },
          {
            name: "title",
            property: "og:title",
            content: mdx.frontmatter.metaTitle,
          },
          {
            name: "image",
            property: "og:image",
            content: mdx.frontmatter.metaImage,
          },
          { name: "twitter:title", content: mdx.frontmatter.metaTitle },
          {
            name: "twitter:description",
            content: mdx.frontmatter.metaDescription,
          },
          { name: "twitter:image", content: mdx.frontmatter.metaImage },
          { name: "twitter:card", content: "summary_large_image" },
        ]}
      />
      <div className="bg-gradient-to-r from-teal-500 to-cyan-600">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:pt-16 sm:pb-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="mt-1 text-2xl font-extrabold text-white sm:text-3xl sm:tracking-tight lg:text-4xl">
              {mdx.frontmatter.title}
            </h1>
            <p className="max-w-xl mx-auto mt-5 text-xl text-gray-200">
              {mdx.frontmatter.subtitle}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white relative max-w-5xl mx-auto">
        <article className="article my-8">
          <p>{mdx.frontmatter.intro}</p>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </article>
      </div>
      <GetStarted />
    </>
  );
};

export default PlaybookArticle;

export const query = graphql`
  query PlaybookArticle($locale: String!, $title: String!) {
    mdx(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        subtitle
        intro
        metaTitle
        metaDescription
        metaImage
        authorName
        authorPosition
        authorPicture
      }
      parent {
        ... on File {
          relativeDirectory
        }
      }
      tableOfContents(maxDepth: 2)
      body
    }
  }
`;
