import React from "react";
import useTranslations from "./useTranslations";

const icons = [
  {
    icon: "/images/logo/get_started/big_query_200_200.png",
    alt: "Logo Big Query",
    left: "86%",
    top: "69%",
    className: "hidden lg:flex",
  },
  {
    icon: "/images/logo/get_started/facebook_200_200.png",
    alt: "Logo Facebook",
    left: "85%",
    top: "2%",
    className: "flex",
  },
  {
    icon: "/images/logo/get_started/ga_200_200.png",
    alt: "Logo Google Analytics",
    left: "34%",
    top: "2%",
    className: "flex",
  },
  {
    icon: "/images/logo/get_started/hubspot_200_200.png",
    alt: "Logo Hubspot",
    left: "12%",
    top: "67%",
    className: "flex",
  },
  {
    icon: "/images/logo/get_started/intercom_200_200.png",
    alt: "Logo Intercom",
    left: "14%",
    top: "37%",
    className: "hidden lg:flex",
  },
  {
    icon: "/images/logo/get_started/mail_chimp_200_200.png",
    alt: "Logo MailChimp",
    left: "79%",
    top: "37%",
    className: "hidden md:flex",
  },
  {
    icon: "/images/logo/get_started/slack_200_200.png",
    alt: "Logo Slack",
    left: "71%",
    top: "15%",
    className: "hidden lg:flex",
  },
  {
    icon: "/images/logo/get_started/snow_flake_200_200.png",
    alt: "Logo Snow Flake",
    left: "75%",
    top: "77%",
    className: "flex",
  },
  {
    icon: "/images/logo/get_started/snowplow_200_200.png",
    alt: "Logo SnowPlow",
    left: "53%",
    top: "75%",
    className: "hidden md:flex",
  },
  {
    icon: "/images/logo/get_started/tik_tok_200_200.png",
    alt: "Logo Tik Tok",
    left: "5%",
    top: "5%",
    className: "flex",
  },
  {
    icon: "/images/logo/get_started/twitter_200_200.png",
    alt: "Logo Twitter",
    left: "34%",
    top: "68%",
    className: "hidden lg:flex",
  },
];

const GetStarted = () => {
  const { gettingStarted, common } = useTranslations();

  return (
    <div className="bg-white">
      <div className="relative w-full text-center mt-16 mb-8 pt-40 pb-56">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900">
          <span className="block">{gettingStarted.title}</span>
        </h2>
        <div className="pt-4">
          <span className="text-2xl font-normal tracking-tight text-gray-500">
            {gettingStarted.subtitle}
          </span>
        </div>
        <div className="mt-8 flex gap-8 justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              target="_blank"
              rel="noopener"
              href={`${process.env.GATSBY_APP_URL}/signup`}
              className="inline-flex items-center justify-center px-10 py-3 border border-transparent text-base font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700"
            >
              {gettingStarted.freeTrial}
            </a>
          </div>
          <div className="inline-flex">
            <a
              target="_blank"
              rel="noopener"
              href={common.bookDemoLink}
              className="inline-flex items-center justify-center px-10 py-3 border border-gray-200 text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-100 cursor-pointer"
            >
              {gettingStarted.demo}
            </a>
          </div>
        </div>
        {icons.map((icon) => (
          <div
            style={{
              left: icon.left,
              top: icon.top,
            }}
            className={`absolute bg-white items-center justify-center shadow-xl h-20 w-20 rounded-lg ${icon.className}`}
          >
            <img src={icon.icon} alt={icon.alt} className="h-12 w-12" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetStarted;
